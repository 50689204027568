// Placeholder of extends
@import '../../core/variables';
@import '../../core/placeholders';
//Generating Menu font color
$color-menu-light: $menu !default;
//Menu Font Color
$color-menu-link-hover: #fff !default;
/* Main Menu Sidebar
------------------------------------
*/
body {
  &.mobile {
    .sidebar-menu {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .sidebar-menu > ul {
      height: auto !important;
      overflow: visible !important;
      -webkit-overflow-scrolling: touch !important;
    }
    .page-sidebar .sidebar-menu .menu-items {
      li {
        &:hover {
          a {
            color: $color-menu-light;
          }
          .icon-thumbnail {
            color: $color-menu-light !important;
          }
        }
        &.active,
        &.open {
          & > a {
            color: #fff;
          }
          & > .icon-thumbnail {
            color: #fff;
          }
        }
      }
    }
    .drager {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  &.sidebar-visible {
    .page-sidebar {
      .scroll-element {
        visibility: visible;
      }
      .menu-items {
        .icon-thumbnail {
          @include translate3d(-14px, 0, 0);
        }
      }
      .sidebar-header .sidebar-header-controls {
        @include translate3d(48px, 0, 0);
      }
    }
  }
}
.page-sidebar {
  width: $layout-sidepanel-width;
  background-color: $color-menu;
  z-index: $zIndex-sidebar;
  left: $layout-sidepanel-width-collapsed - $layout-sidepanel-width;
  position: fixed;
  bottom: 0;
  top: 0;
  right: auto;
  overflow: hidden;
  -webkit-transition: -webkit-transform 400ms cubic-bezier($menu-bezier);
  -moz-transition: -moz-transform 400ms cubic-bezier($menu-bezier);
  -o-transition: -o-transform 400ms cubic-bezier($menu-bezier);
  transition: transform 400ms cubic-bezier($menu-bezier);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000px;
  a,
  button {
    color: $color-menu-light;
    &:hover,
    &:active {
      color: $color-menu-link-hover;
      background-color: #ee8989 !important;
      &:focus {
        color: $color-menu-link-hover;
      }
    }
    &:visited,
    &:focus {
      color: $color-menu-light;
    }
  }
  button.sidebar-slide-toggle {
    //BS 4 fixes
    &.active {
      color: $color-menu-light;
      &:focus {
        background: transparent;
      }
    }
  }
  .scroll-element {
    visibility: hidden;
  }
  .sidebar-header {
    /* Side-bar header */
    display: block;
    height: $layout-header-height;
    line-height: $layout-header-height;
    color: $color-white;
    width: 100%;
    padding: 0 20px;
    padding-left: 15px;
    clear: both;
    z-index: 10;
    position: relative;
    border-bottom: 1px solid #423b3b;
    .sidebar-header-controls {
      display: inline-block;
      -webkit-transition: -webkit-transform 0.4s cubic-bezier($menu-bezier);
      transition: transform 0.4s cubic-bezier($menu-bezier);
      -webkit-backface-visibility: hidden;
      margin-left: 30px;
      top: -4px;
      position: relative;
      button{
        &:hover{
          background: transparent!important;
        }
      }
    }
    img{
      position: relative;
      top: -3px;
    }
    .sidebar-slide-toggle i {
      @include transition(all 0.12s ease);
    }
    .sidebar-slide-toggle.active i {
      @include rotate(-180deg);
    }
  }
  .close-sidebar {
    position: absolute;
    right: 19px;
    top: 14px;
    padding: 9px;
    z-index: 1;
    & > i {
      color: rgba(255, 255, 255, 0.57);
    }
  }
  .sidebar-overlay-slide {
    /* Side-bar Top Slider */
    width: 100%;
    height: 100%;
    background-color: $color-menu-darker;
    display: block;
    z-index: 9;
    padding: $layout-header-height + 20 20px 20px 20px;
    &.from-top {
      top: -100%;
      position: absolute;
      @include transition(all 0.2s ease);
      &.show {
        @include translate(0, 100%);
      }
    }
  }
  .sidebar-menu {
    /* Side-bar Menu */
    height: 100%;
    position: relative;
    width: 100%;
    .outer-tab-nav-section {
      display: inline-block;
      width: 45px;
      position: absolute;
      height: 100%;
      background-color: #0aa699 !important;
    }
    .menu-items {
      /* Side-bar Menut Items */
      list-style: none;
      margin: 0;
      padding: 0;
      margin: 0;
      padding: 0;
      position: relative;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 10px - 60px);
      width: 100%;
      li {
        &:hover,
        &.open,
        &.active {
          .icon-thumbnail {
            color: $color-menu-link-hover;
          }
          a {
            color: $color-menu-link-hover;
          }
        }
        & > a,
        & > span.sub-label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          color: #fff;
          span.title{
            position: relative;
            left: -15px;
          }
        }
      }
      & > li {
        display: block;
        padding: 0;
        clear: right;
        &:after,
        &:before {
          display: table;
          content: ' ';
          clear: both;
        }
        & > a,
        & > span.sub-label {
          font-family: Arial, sans-serif;
          display: flex;
          padding-left: 20px;
          align-items: center;
          min-height: $menu-icon-size;
          line-height: $menu-icon-size;
          font-size: 14px;
          clear: both;
          position: relative;
          &.open {
            background: #313131;
          }
          & > .arrow {
            &:before {
              float: right;
              display: inline;
              @extend %extend-material-icons;
              content: 'keyboard_arrow_down';
              font-size: 26px; // font-family: FontAwesome;
              height: auto;
              font-weight: 300;
              text-shadow: none;
              @include transition(all 0.12s ease);
            }
            &.open {
              &:before {
                @include rotate(180deg);
              }
            }
          }
          & > .badge {
            margin-top: 12px;
          }
          & > .title {
            float: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          & > .details {
            font-size: 12px;
            opacity: 0.4;
            display: block;
            clear: both;
          }
          &.detailed {
            & > .title {
              line-height: 28px;
            }
            & > .details {
              line-height: 16px;
            }
          }
        }
        &.active,
        &.open {
          & > ul {
            &.sub-menu {
              display: block;
            }
          }
        }
        & > .icon-thumbnail {
          &:first-letter {
            text-transform: uppercase;
          }
        }
        &:first-child{
          margin-top: 10px;
        }
        & > ul {
          &.sub-menu {
            & > li {
              line-height: initial;
              ul {
                &.sub-menu {
                  li {
                    padding-left: 10px;
                    padding-right: 3px;
                  }
                  .icon-thumbnail {
                    width: 25px;
                    height: 25px;
                    line-height: 25px;
                    font-size: 10px;
                  }
                }
              }
            }
            li {
              & > a,
              & > span.sub-label {
                & > .arrow {
                  &:before {
                    float: right;
                    margin-top: 1px;
                    margin-right: 20px;
                    display: inline;
                    font-size: 16px;
                    font-family: FontAwesome;
                    height: auto;
                    content: '\f104';
                    font-weight: 300;
                    text-shadow: none;
                    @include transition(all 0.12s ease);
                  }
                  &.open {
                    &:before {
                      float: right;
                      margin-top: 1px;
                      margin-right: 18px;
                      display: inline;
                      font-family: FontAwesome;
                      height: auto;
                      font-size: 16px;
                      @include rotate(-90deg);
                      font-weight: 300;
                      text-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
        ul {
          &.sub-menu {
            display: none;
            list-style: none;
            clear: both;
            margin: 0;
            padding: 0;
            background-color: $color-menu-dark;
            background-color: #2A2A26;
            & > li {
              background: none;
              padding-left: 0;
              &:hover {
                & > .icon-thumbnail {
                  color: $color-menu-link-hover;
                }
              }
              & > a,
              & > span.sub-label {
                font-size: 13px;
                font-family: Arial, sans-serif;
                white-space: normal;
                width: 100%;
                padding-left: 20px;
                margin-bottom: 0;
                margin-top: 3px;
              }
              & > a > span.title {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 30px;
              }
              .icon-thumbnail {
                line-height: 21px;
                margin: 0;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .muted {
      color: #576775;
      opacity: 0.45;
    }
  }
  .icon-thumbnail [class^='bg-'],
  [class*='bg-'] {
    color: #fff;
  }
}
[data-toggle-pin='sidebar'] {
  & > i:before {
    font-size: 15px !important;
    top: -4px;
    position: relative;
    @extend %extend-material-icons;
    content: 'panorama_fish_eye';
  }
}
/* Sidebar icon holder
------------------------------------
*/
.page-sidebar{
  .icon-thumbnail {
    display: inline-block;
    height: $menu-icon-size;
    width: $menu-icon-size;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    left: 0;
    color: $grey;
    font-size: 16px;
    -webkit-transition: -webkit-transform 0.4s cubic-bezier($menu-bezier);
    transition: transform 0.4s cubic-bezier($menu-bezier);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000px;
    font-family: $base-font-family;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-weight: bold;
    & > svg {
      width: auto;
      height: auto;
      display: inline;
    }
    i{
      display: inline-block;
      min-width: 1.8rem;
      font-size: 20px;
      line-height: 1.40625rem;
      vertical-align: middle;
    }
  }
  .link-item{
    margin-bottom: 3px;
    align-items: center;
  }
  .link-item.active {
    background-color: #f66;
    span {
      color: #fff;
      i {
        color: #fff;
      }
    }
    &:hover{
      background-color: #f66!important;
    }
  }
  .button-item{
    padding-left: 20px!important;
    display: flex;
    align-items: center;
    border-radius: 0;
    margin: 3px 0;
    .title{
      position: relative;
      left: -15px!important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 30px;
      font-size: 14px;
      font-size: 13px;
    }
  }
  .home-link{
    .arrow{
      position: absolute;
      right: 20px;
    }
  }
}
li.cursor.open{
  a.link-item{
    margin-bottom: 0!important;
  }
  .sub-menu{
    li.section-link:first-child{
      padding-top: 2.5px!important;
      a{
        margin-top: 0!important
      };
    }
    li.section-link:last-child{
      padding: 0 0 3px 0!important;
    }
    li.new-section{
      padding: 3px 0!important;
    }
    .button-item{
      margin: 0;
    }
  }
}
@media(max-width: 991px){
  .page-sidebar{
    .sidebar-header{
      display: flex !important;
      align-items: center;
      border: 0;
    }
    .sidebar-menu .menu-items>li>a {
      padding-left: 5px !important;
    }
    .brand{
      margin: 0;
      top: 0!important
    }
    ul.sub-menu{
      li{
        padding: 0!important;
        a{
          padding-left: 5px !important;
          span.title{
            left: 0!important;
          }
        }
      }
      .button-item{
        padding-left: 5px !important;
        span.title{
          left: 0!important;
        }
      }
    }
  }
  .fixed-header.sidebar-visible, .fixed-header.menu-pin, .fixed-header.sidebar-open{
    span.title{
      left: 0!important;
    }
    div.brand{
      display: none!important;
    }
    .sidebar-header{
      border: 0!important;
    }
  }
}
.fixed-header{
  .link-item, .button-item{
    display: block!important;
  }
  span.icon-thumbnail{
    float: right;
    margin-right: 15px!important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span.arrow{
    display: none;
  }
}
.fixed-header.sidebar-visible, .fixed-header.menu-pin, .fixed-header.sidebar-open{
  .link-item, .button-item{
    display: flex!important;
  }
  span.icon-thumbnail{
    float: none;
    margin-right: 0!important;
    display: inline-block;
  }
  span.arrow{
    display: block;
  }
}
.fixed-header.sidebar-visible, .fixed-header.menu-pin, .fixed-header.sidebar-open{
  .sidebar-header{
    border-bottom: 1px solid #423b3b;
  }
}
.sidebar-menu{
  ::-webkit-scrollbar {
    display: none;
  }
}