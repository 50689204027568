.loadingTable {
  width: 100%;
  height: 200px;
  position: relative;

  .lds-ellipsis {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 50px;
    height: 50px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ff5722;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
}
.modal-choose-alias-accounts {
  .modal-body {
    button {
      color: #000;
      width: 100%;
      margin-bottom: 5px;
      padding: 12px;
      box-shadow: 1px 1px 1px 1px #eee !important;
      transition: all 0.2s ease !important;
      text-align: left;
      &:hover,
      &:focus {
        border: 1px solid #ccc !important;
        box-shadow: 1px 1px 1px 1px #ccc !important;
        transition: all 0.2s ease !important;
      }
    }
    .modal-description{
      margin-bottom: 30px;
      text-align: center;
      h3{
        margin: 0;
      }
      p{
        font-size: 16px;
        span{
          color: rgb(17, 102, 187);
          font-weight: bold;
        }
      }
    }
  }
  .modal-dialog {
    box-shadow: none !important;
    max-width: 550px!important;
  }
  h5.modal-title{
    font-size: 16px!important;
    img{
      max-width: 60px;
    }
  }
  .modal-content{
    border: 1px solid #ccc;
  }
  .alias-avatar-item{
    display: flex;
    span{
      position: relative;
      top: 14px;
      font-size: 16px;
    }
    .avatar-content{
      width: 50px;
      margin-right: 15px;
      position: relative;
      height: 50px;
      &:after{
        content: 'A';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 999;
        background: #D11A35;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .main-avatar-item{
    .avatar-content{
      &:after{
        content: 'M';
        background: #EE7421;
      }
    }
  }
}





